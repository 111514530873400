import React, { useEffect, useRef } from 'react'

function Navigation({ isOpen, onClose }) {
  const navRef = useRef(null)
  const overlayRef = useRef(null)

  const translateClass = isOpen ? 'translate-x-0' : 'translate-x-full'
  const overlayClass = isOpen ? 'opacity-90' : 'opacity-0 pointer-events-none'

  useEffect(() => {
    if (isOpen) {
      document.documentElement.classList.add('overflow-hidden')
    } else {
      document.documentElement.classList.remove('overflow-hidden')
    }

    return () => {
      document.documentElement.classList.remove('overflow-hidden')
    }
  }, [isOpen])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target) && !overlayRef.current.contains(event.target)) {
        onClose()
      }
    }

    const handleOverlayClick = (event) => {
      if (overlayRef.current && overlayRef.current.contains(event.target)) {
        onClose()
      }
    }

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside)
      document.addEventListener('click', handleOverlayClick)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('click', handleOverlayClick)
    }
  }, [isOpen, onClose])

  const scrollToSection = (id) => {
    const section = document.getElementById(id)
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' })
      onClose() // Fecha o menu após a rolagem
    }
  }

  return (
    <>
      <div
        ref={overlayRef}
        className={`fixed inset-0 z-40 bg-black ${overlayClass} backdrop-blur-xl backdrop-filter transition-opacity duration-1000 ease-in-out`}
      />

      <div
        ref={navRef}
        className={`fixed inset-y-0 right-0 z-50 flex w-10/12 items-center justify-center bg-[#545454] shadow-lg transition-transform duration-500 ease-in-out md:w-1/3 ${translateClass}`}
      >
        <nav className='m-8 flex w-full flex-col gap-4'>
          <ul className='flex flex-col gap-4'>
            <li>
              <button onClick={() => scrollToSection('inicio')} className='text-4xl font-semibold text-white hover:underline'>
                Início
              </button>
            </li>
            <li>
              <button onClick={() => scrollToSection('quem-somos')} className='text-4xl font-semibold text-white hover:underline'>
                Quem Somos
              </button>
            </li>
            <li>
              <button onClick={() => scrollToSection('tratamentos')} className='text-4xl font-semibold text-white hover:underline'>
                Tratamentos
              </button>
            </li>
            <li>
              <button onClick={() => scrollToSection('clinica')} className='text-4xl font-semibold text-white hover:underline'>
                Clínica
              </button>
            </li>
            <li>
              <button onClick={() => scrollToSection('contato')} className='text-4xl font-semibold text-white hover:underline'>
                Contato
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </>
  )
}

export default Navigation