import React from 'react';
import imageLg from 'assets/images/banner.png';
import imageSm from 'assets/images/banner-sm.png';

function Banner({ data }) {
  return (
    <section
      id='inicio'
      className='bg-lightGray relative flex min-h-dvh items-end justify-center overflow-hidden transition-opacity duration-1000'
    >
      <picture className="w-full h-auto">
        <source media="(max-width: 768px)" srcSet={imageSm} />
        <source media="(min-width: 769px)" srcSet={imageLg} />
        <img
          src={imageLg}
          alt="Banner"
          className="w-full h-auto object-cover"
        />
      </picture>
    </section>
  );
}

export default Banner;
