import React from 'react'
import useScrollVisibility from 'hooks/useScrollVisibility'

function OurSpace({ data }) {
  const [isVisible, sectionRef] = useScrollVisibility('our-space-section')

  return (
    <section
      ref={sectionRef}
      id='clinica'
      className='flex w-full flex-col items-center justify-center bg-light py-8'
    >
      <div className={`flex w-11/12 md:w-9/12 flex-col gap-8 overflow-hidden ${isVisible ? 'animate-scaleUp' : 'opacity-0' }`}>
        <header className={`flex flex-col gap-2 justify-end items-center text-center`}>
          <h2 className='text-gray-900 text-4xl font-semibold md:text-5xl'>Galeria da Clínica</h2>
          <p className='text-xl font-medium'>Conheça nosso espaço</p>
        </header>

        <div className='flex md:grid md:grid-cols-3 gap-4 overflow-x-auto'>
          {data.map((image, index) => (
            <picture key={index} className='w-full'>
              <img
                src={image.image}
                alt={image.name}
                className={`min-w-80 h-64 w-full rounded-lg object-cover`}
              />
            </picture>
          ))}
        </div>
      </div>
    </section>
  )
}

export default OurSpace
