import { RouterProvider, createHashRouter } from 'react-router-dom';
import Home from 'pages/Home/Home';
// import About from 'pages/About/About';
import OurTreatments from 'pages/OurTreatments/OurTreatments';
// import OurSpace from 'pages/OurSpace/OurSpace';

const routes = createHashRouter([
  { path: '/', element: <Home /> },
  // { path: '/sobre/:name', element: <About /> },
  { path: '/tratamentos/:name', element: <OurTreatments /> },
  // { path: '/clinica', element: <OurSpace /> },
]);

export default function App() {
  return <RouterProvider router={routes} />;
}

// import { RouterProvider, createBrowserRouter } from 'react-router-dom'
// import Home from 'pages/Home/Home'
// import About from 'pages/About/About'
// import OurTreatments from 'pages/OurTreatments/OurTreatments'
// import OurSpace from 'pages/OurSpace/OurSpace'

// const routes = createBrowserRouter([
//   { path: '/', element: <Home /> },
//   // { path: '/sobre/:name', element: <About /> },
//   { path: '/tratamentos/:name', element: <OurTreatments /> },
//   // { path: '/clinica', element: <OurSpace /> },
// ])

// export default function App() {
//   return <RouterProvider router={routes} />
// }

