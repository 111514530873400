import React, { useState, useEffect } from 'react'
import whatsappIcon from 'assets/icons/whatsapp.svg'

function Whatsapp({ message }) {
  const whatsappUrl = `https://api.whatsapp.com/send?phone=5543999512211&text=${encodeURIComponent(message)}`
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true)
    }, 1000)

    return () => clearTimeout(timer)
  }, [])

  return (
    <a
      href={whatsappUrl}
      target='_blank'
      rel='noopener noreferrer'
      className={`fixed bottom-4 right-4 hover:opacity-85 md:bottom-10 md:right-10 ${isVisible ? 'animate-scale-up' : 'opacity-0'}`}
    >
      <picture>
        <img src={whatsappIcon} alt='Whatsapp' className='h-20 w-20' />
      </picture>
    </a>
  )
}

export default Whatsapp
