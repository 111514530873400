import React from 'react'
import { Link } from 'react-router-dom'
import useScrollVisibility from 'hooks/useScrollVisibility'

function About({ data, reverse }) {
  const [isVisible, sectionRef] = useScrollVisibility('about-section')

  const textAnimationClass = isVisible ? (reverse ? 'animate-slideInRight' : 'animate-slideInLeft') : 'opacity-0'
  const imageAnimationClass = isVisible ? (reverse ? 'animate-slideInLeft' : 'animate-slideInRight') : 'opacity-0'

  return (
    <section
      ref={sectionRef}
      id='quem-somos'
      className={`flex w-full items-center justify-center ${reverse ? 'bg-white' : 'bg-light'}`}
    >
      <div className={`flex flex-col-reverse items-center gap-8 ${reverse ? 'md:flex-row-reverse' : 'md:flex-row'} m-4 w-11/12 md:w-9/12 md:my-20`}>
        <div className={`flex w-full flex-col gap-4 md:w-1/2 ${textAnimationClass}`}>
          <header className='flex flex-col gap-2'>
            {data.name && <h2 className='text-gray-900 text-4xl font-bold md:text-6xl'>{data.name}</h2>}
            {data.cro && <p className='text-gray-800 text-xl md:text-2xl'>{data.cro}</p>}
          </header>

          <div className='flex flex-col gap-2'>
            {data.paragraphs &&
              data.paragraphs.map((paragraph, index) => (
                <p key={index} className='text-darkGray text-justify text-base md:text-xl'>
                  {paragraph}
                </p>
              ))}
          </div>

          {data.link && (
            <button className='max-w-[75%] md:max-w-[50%] rounded-full border-2 border-gray p-4 px-8 text-xl md:text-2xl font-medium text-gray transition-all duration-500 hover:opacity-75'>
              <a href='https://api.whatsapp.com/send?phone=5543999512211&text=Ol%C3%A1,%20gostaria%20de%20agendar%20uma%20consulta.' target='_blank'>Entre em Contato</a>
            </button>
          )}
        </div>

        {data.image && (
          <picture className={`flex w-full md:w-1/2 ${imageAnimationClass}`}>
            <img src={data.image} alt={data.name} className='w-full rounded-xl' />
          </picture>
        )}
      </div>
    </section>
  )
}

export default About
