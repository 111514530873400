import React from 'react'
import Header from 'components/Header'
import Banner from './components/Banner'
import About from './components/About'
import Footer from 'components/Footer'
import Whatsapp from 'components/Whatsapp'
import Contact from './components/Contact'
import DentalCare from './components/DentalCare'
import OurSpace from './components/OurSpace'

import aline from 'assets/images/dra-aline.jpg'
import luis from 'assets/images/dr-luis.jpg'

import image1 from 'assets/images/tratamento (1).jpg' 
import image2 from 'assets/images/tratamento (2).jpg' 
import image3 from 'assets/images/tratamento (3).jpg' 
import image4 from 'assets/images/tratamento (4).jpg' 
import image5 from 'assets/images/tratamento (5).jpg' 
import image6 from 'assets/images/tratamento (6).jpg' 
import image7 from 'assets/images/tratamento (7).jpg' 
import image8 from 'assets/images/tratamento (8).jpg' 
import image9 from 'assets/images/tratamento (9).jpg'

import imageTeste from 'assets/images/imageTeste.svg' 

function Home() {
  const Aline = {
    name: 'Dra. Aline Vila Real',
    cro: 'CRO-PR 17768',
    paragraphs: [
      'Me formei na Universidade Estadual de Maringá e busquei aprimorar minha formação com diversas especializações, além de participar de vários cursos de atualização. Destaco também a oportunidade enriquecedora de realizar um curso nos Estados Unidos, ampliando meu conhecimento e habilidades na área odontológica.',
      'Essa trajetória acadêmica sólida tem sido a base do meu compromisso com a odontologia de excelência e cuidado personalizado. Ao longo dos anos, na minha clínica, tenho o privilégio de aplicar esse conhecimento avançado em benefício dos meus pacientes. Cada tratamento é realizado com dedicação e precisão, priorizando não apenas a saúde bucal, mas também o conforto e a confiança de cada pessoa que confia em meu trabalho.',
    ],
    image: aline,
    link: 'sobre/aline',
  }

  const Luis = {
    name: 'Dr. Luis Vila Real',
    cro: 'CRO-PR 23936',
    paragraphs: [
      'Me formei na Universidade Estadual de Maringá e busquei aprimorar minha formação com diversas especializações, além de participar de vários cursos de atualização. Destaco também a oportunidade enriquecedora de realizar um curso nos Estados Unidos, ampliando meu conhecimento e habilidades na área odontológica.',
      'Essa trajetória acadêmica sólida tem sido a base do meu compromisso com a odontologia de excelência e cuidado personalizado. Ao longo dos anos, na minha clínica, tenho o privilégio de aplicar esse conhecimento avançado em benefício dos meus pacientes. Cada tratamento é realizado com dedicação e precisão, priorizando não apenas a saúde bucal, mas também o conforto e a confiança de cada pessoa que confia em meu trabalho.',
    ],
    image: luis,
    link: 'sobre/luis',
  }

  
  const otherTreatments = [
    { name: 'restauracoes-dentarias', image: image1 },
    { name: 'tratamento-bruxismo', image: image2 },
    { name: 'tratamento-endodontico', image: image3 },
    { name: 'clareamento-dental', image: image4 },
    { name: 'aparelho-ronco', image: image5 },
    { name: 'extracoes-dentarias', image: image6 },
  ]
  
  const featuredTreatments = [
    { name: 'facetas-porcelana', image: image7 },
    { name: 'aparelho-ortodontico', image: image8 },
    { name: 'implantes-dentarios', image: image9 },
  ]
  
  const clinicPhotos = [
    { name: 'aqui vai a mensagem do alt', image:imageTeste },
    { name: 'aqui vai a mensagem do alt', image:imageTeste },
    { name: 'aqui vai a mensagem do alt', image:imageTeste },
    { name: 'aqui vai a mensagem do alt', image:imageTeste },
    { name: 'aqui vai a mensagem do alt', image:imageTeste },
    { name: 'aqui vai a mensagem do alt', image:imageTeste },
  ]

  return (
    <main>
      <Header />
      <Banner />
      <About data={Aline} reverse={true} />
      <About data={Luis} reverse={false} />
      <DentalCare featuredTreatments={featuredTreatments} otherTreatments={otherTreatments} />
      <OurSpace data={clinicPhotos} />
      <Contact />
      <Footer />
      <Whatsapp message={'Olá, vim pelo site'} />
    </main>
  )
}

export default Home
