import React from 'react'
import logo from 'assets/icons/logo.svg'

function Header() {    
    return (    
        <header className='p-4 bg-light'>
            <a href='https://vilarealodontologia.com.br'>
                <img src={logo} alt='' className='w-56' />
            </a>
        </header>
    )
}

export default Header